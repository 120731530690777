<template>
  <div>
    <vs-row>
      <vs-col vs-w="3">
        <miscellaneous-control
          @fetchedData="assign"
          :current_page="current_page"
          :course="course"
        ></miscellaneous-control>
      </vs-col>
      <vs-col vs-w="9">
        <vs-card>
          <div slot="header">
            <h4>{{ `${course} Books Delivery` }}</h4>
          </div>
          <div style="height:500px; overflow-y: auto">
            <purchases :purchases_data="purchases_data"></purchases>
          </div>
        </vs-card>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-justify="flex-end" vs-w="12">
        <vs-pagination
          :total="total_pages"
          v-model="current_page"
        ></vs-pagination>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import MiscellaneousControl from "./MiscellaneousControl.vue"
import purchases from "./purchases.vue";
export default {
  components: {
    purchases,
    MiscellaneousControl,
  },
  props: {
    course: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      total_pages: 1,
      current_page: 1,
      purchases_data: [],
    };
  },
  methods: {
    assign(response) {
      console.log(response);
      this.total_pages = response.data.warehouse_data.last_page;
      this.current_page = response.data.warehouse_data.current_page;
      this.purchases_data = response.data.warehouse_data.data;
    },
  },
};
</script>

<style></style>
